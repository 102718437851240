<template>
  <div class="ai-template-page">
    <LoadingOverlay v-if="isLoading" />
    <div
      class="container-md col-lg-8 col-md-8"
      v-if="!isProcessing && !htmlString"
    >
      <CCard>
        <CCardBody>
          <h5 class="text-primary">Profile Page Builder</h5>
          <ValidationObserver ref="templateForm" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)" name="templateForm">
              <CRow>
                <CCol md="6">
                  <CRow class="mb-3">
                    <label class="col-md-12 required">Profile Name</label>
                    <CCol md="12">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <TextInput
                          name="name"
                          :value="userData.name"
                          @input="handleChangeInput"
                          :error="errors[0]"
                        />
                      </ValidationProvider>
                    </CCol>
                  </CRow>
                  <CRow class="mb-3">
                    <label class="col-md-12 required">Content Type</label>
                    <CCol md="12">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <Select
                          name="content_type"
                          :value="userData.content_type"
                          @change="handleChangeSelect"
                          :options="
                            options && options['contentTypes']
                              ? options['contentTypes']
                              : []
                          "
                          :taggable="false"
                          :multiple="false"
                          :clearable="false"
                          :error="errors[0]"
                        />
                      </ValidationProvider>
                    </CCol>
                  </CRow>
                  <CRow
                    class="mb-3"
                    v-if="userData.content_type.code === 'doc'"
                  >
                    <label class="col-md-12 required">Content Document</label>
                    <CCol md="12">
                      <input
                        id="document-upload"
                        name="upload-document"
                        type="file"
                        ref="fileref"
                        :accept="arrayToComma(acceptedFile)"
                        @change="selectedFile"
                      />
                    </CCol>
                  </CRow>
                  <CRow class="mb-3" v-else>
                    <label class="col-md-12 required">Content URL</label>
                    <CCol md="12">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <TextInput
                          name="url"
                          :value="userData.url"
                          @input="handleChangeInput"
                          :error="errors[0]"
                        />
                      </ValidationProvider>
                    </CCol>
                  </CRow>
                </CCol>
                <CCol md="6">
                  <CRow class="mb-3">
                    <label class="col-md-12 required">Template</label>
                    <CCol md="12">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <Select
                          name="template"
                          :value="userData.template"
                          @input="handleChangeSelect"
                          :error="errors[0]"
                          :options="
                            options && options['templates']
                              ? options['templates']
                              : []
                          "
                          :taggable="false"
                          :multiple="false"
                          :clearable="false"
                        />
                      </ValidationProvider>
                    </CCol>
                  </CRow>
                  <CRow class="mb-3">
                    <label class="col-md-12 required">Short Description</label>
                    <CCol md="12">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <TextAreaInput
                          name="description"
                          :value="userData.description"
                          @input="handleChangeInput"
                          :error="errors[0]"
                          :rows="5"
                          CSS_Style="resize: none;"
                        />
                      </ValidationProvider>
                    </CCol>
                  </CRow>
                </CCol>

                <CCol md="6">
                  <CRow class="mb-3">
                    <label class="col-md-12 required">Home URL</label>
                    <CCol md="12">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <TextInput
                          name="home_url"
                          :value="userData.home_url"
                          @input="handleChangeInput"
                          :error="errors[0]"
                        />
                      </ValidationProvider>
                    </CCol>
                  </CRow>
                  <CRow class="mb-3">
                    <label class="col-md-12 required">Register URL</label>
                    <CCol md="12">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <TextInput
                          name="register_url"
                          :value="userData.register_url"
                          @input="handleChangeInput"
                          :error="errors[0]"
                        />
                      </ValidationProvider>
                    </CCol>
                  </CRow>
                </CCol>
                <CCol md="6">
                  <CRow class="mb-3">
                    <label class="col-md-12 required">Login URL</label>
                    <CCol md="12">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <TextInput
                          name="login_url"
                          :value="userData.login_url"
                          @input="handleChangeInput"
                          :error="errors[0]"
                        />
                      </ValidationProvider>
                    </CCol>
                  </CRow>
                </CCol>
              </CRow>

              <div class="d-flex justify-content-end">
                <CButton
                  type="submit"
                  color="primary"
                  shape="pill"
                  :disabled="isGenerating"
                >
                  <CSpinner
                    v-if="isGenerating"
                    class="spinner-border-sm text-white mr-1"
                  />
                  <strong>{{ isGenerating ? "Fetching" : btnText }}</strong>
                </CButton>
              </div>
            </form>
          </ValidationObserver>
        </CCardBody>
      </CCard>
    </div>
    <div v-if="!isProcessing && htmlString">
      <div class="d-flex justify-content-between">
        <h5 class="text-primary">Preview</h5>
        <div>
          <button
            name="btn-edit"
            class="btn text-primary"
            v-if="!isEditMode"
            @click="startEditMode"
          >
            <span class="mr-1">Edit</span>
            <i class="fa-solid fa-pen-to-square"></i>
          </button>
          <button
            name="btn-regenerate"
            class="btn text-primary"
            @click="regenerateTemplate"
          >
            <span class="mr-1">Regenerate</span>
            <i class="fa-solid fa-arrows-rotate"></i>
          </button>
          <button
            name="btn-cancel"
            class="btn text-primary"
            v-if="isEditMode"
            @click="stopEditMode"
          >
            <span class="mr-1">Cancel</span>
            <i class="fa-solid fa-xmark"></i>
          </button>
          <button
            class="btn text-primary"
            @click="saveTemplate"
            name="btn-save"
          >
            <span class="mr-1">Save</span>
            <i class="fa-regular fa-floppy-disk"></i>
          </button>
        </div>
      </div>
      <CCard>
        <CCardBody class="preview-body">
          <div v-if="htmlString">
            <iframe
              id="iframe"
              ref="iframe"
              :srcdoc="htmlString"
              onload='javascript:(function(o){o.style.height=o.contentWindow.document.body.scrollHeight+"px";}(this));'
              style="height: 100vh; width: 100%; border: none; overflow: hidden"
            ></iframe>
          </div>
          <img
            class="place_holder_img"
            src="/img/website_placeholder.png"
            v-if="!htmlString"
            width="100%"
            alt="No Data"
          />
        </CCardBody>
      </CCard>
    </div>
    <div v-else-if="isProcessing">
      <div class="container-md col-lg-8 col-md-8 position-relative">
        <div class="d-flex justify-content-between">
          <h4 class="text-primary">Profile Page</h4>
          <button
              name="btn-delete"
              class="btn text-primary"
              @click="openDeleteModal"
            >
              <span class="mr-1">Stop</span>
              <i class="fa-solid fa-trash"></i>
            </button>
        </div>
        <div class="d-flex justify-content-center mt-3">
          <p class="h3 text-muted">Generation In Progress...</p>
        </div>
        <!-- <div class="skeleton-card"></div> -->

        <div class="stepper-container">
          <!-- Progress Bar -->
          <div class="stepper">
            <div class="stepper-progress" :style="{ width: progressWidth }"></div>
          </div>

          <!-- Steps -->
          <div v-for="(step, index) in steps" :key="index" class="step-wrapper">
            <div
              class="step"
              :class="{ active: index <= currentStep }"
            >
              ✓
            </div>
            <div 
              class="step-message"  
              :class="{ 'text-green': index <= currentStep }"
            >
              {{ upperCase(stepMessages[contentType][index]) }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <CSidebar fixed :minimize="false" :show.sync="isShowEdit" v-if="isShowEdit">
      <CSidebarHeader class="bg-clr" :withSubheader="true">
        <div>
          <h4 class="text-primary">Edit {{ selectedElement.tagName }}</h4>
        </div>
      </CSidebarHeader>
      <div class="m-3">
        <div v-if="['IMG', 'SECTION'].includes(selectedElement.tagName)">
          <label
            class="text-primary required"
            v-if="selectedElement.tagName === 'IMG'"
            >Image</label
          >
          <label class="text-primary required" v-else>Background Image</label>
          <input
            ref="input_file"
            name="input_file"
            type="file"
            accept="image/*"
            @change="handleSelectedFile"
          />
        </div>
        <div v-else>
          <label class="text-primary required">Text Content</label>
          <TextAreaInput
            name="textContent"
            :value="selectedElement.textContent"
            @change="handleTextInput"
            :error="selectedElement.error"
            :rows="5"
          />
        </div>
        <div
          v-if="selectedElement.color && !selectedElement.isBackIMG"
          class="mt-3"
        >
          <label class="text-primary required mb-0">Text Color</label>
          <LvColorpicker
            :value="selectedElement.color"
            hidePalette
            @input="handleColorChange"
          />
        </div>

        <div class="d-flex justify-content-end mt-3">
          <CButton
            color="primary"
            class="mx-2"
            variant="outline"
            @click="updateElement(false)"
            >Cancel</CButton
          >
          <CButton class="mx-2" color="secondary" @click="deleteElement(true)"
            >Delete</CButton
          >
          <CButton color="primary" @click="updateElement(true)">Update</CButton>
        </div>
      </div>
    </CSidebar>
    <CropperModal
      v-if="cropperModal.isShowPopup"
      :isShowPopup="cropperModal.isShowPopup"
      :fileData="cropperModal.fileData"
      modalColor="primary"
      :buttonLabel="cropperModal.buttonLabel"
      @modalCallBack="cropperModalCallBack"
      :aspectRatio="cropperModal.aspectRatio"
    />
    <Modal
      v-if="deleteConfirmModal.isShowPopup"
      :modalTitle="deleteConfirmModal.modalTitle"
      :modalColor="deleteConfirmModal.modalColor"
      :modalContent="deleteConfirmModal.modalContent"
      :isShowPopup="deleteConfirmModal.isShowPopup"
      :buttons="deleteConfirmModal.buttons"
      :modalCallBack="deleteModalCallBack"
      :closeOnBackdrop="false"
    />
  </div>
</template>
<script>
import TextAreaInput from "@/components/reusable/Fields/TextareaInput";
import TextInput from "@/components/reusable/Fields/TextInput";
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import CropperModal from "@/components/reusable/CropperModal";
extend("required", { ...required, message: "This field is required" });
import Select from "@/components/reusable/Fields/Select.vue";
import LvColorpicker from "lightvue/color-picker";
import Modal from "@/components/reusable/Modal";
export default {
  props: {
    isEditModeInfo: {
      type: Boolean,
      default: false,
    },
    htmlFileString: {
      type: String,
      default: null,
    },
  },
  components: {
    TextAreaInput,
    TextInput,
    CropperModal,
    LvColorpicker,
    Select,
    Modal,
  },
  data() {
    return {
      acceptedFile: ["pdf", "doc", "docx"],
      htmlString: null,
      userData: {
        content_type: {
          code: "doc",
          label: "Document",
        },
        template: {
          code: "template1",
          label: "Template 1",
        },
        // description: "English course for medical professionals",
      },
      btnText: "Submit 🚀",
      isGenerating: false,
      isShowEdit: false,
      isEditMode: false,
      selectedElement: {},
      cropperModal: {
        isShowPopup: false,
        fileData: null,
        buttonLabel: "Apply",
        aspectRatio: 0,
        data: null,
      },
      templateRes: null,
      deleteConfirmModal: {
        modalColor: "danger",
        modalTitle: "NOTIFICATION",
        modalContent: "",
        isShowPopup: false,
        buttons: ["Cancel", "Confirm"],
        data: null,
      },
      interval: null,
      steps: [1, 2, 3, 4, 5, 6],
      currentStep: 0,
      stepMessages:  {
        file: [
          "started",
          "file_received",
          "file_analyzed",
          "processing",
          "generating",
          "completed",
        ],
        url: [
          "started",
          "file_received",
          "fetching_content",
          "processing",
          "generating",
          "completed",
        ],
      },
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters(["getOrgIDFromAccessToken", "generatedPartnerRes"]),
    options() {
      return {
        templates: [
          { code: "template1", label: "Template 1" },
          { code: "template2", label: "Template 2" },
        ],
        contentTypes: [
          { code: "url", label: "URL" },
          { code: "doc", label: "Document" },
        ],
      };
    },
    isCompleted() {
      return this.generatedPartnerRes?.completed || false;
    },
    isSubmitted() {
      return this.generatedPartnerRes?.submitted || false;
    },
    isProcessing() {
      return this.generatedPartnerRes
        ? !this.htmlString && this.generatedPartnerRes.completed === false
        : false;
    },
    progressWidth() {
      return `${(this.currentStep / (this.steps.length - 1)) * 100}%`;
    },
    contentType() {
      const content_type = this.userData?.content_type?.code == 'doc' ? 'file' : 'url'
      return content_type
    }
  },
  methods: {
    ...mapActions([
      "showToast",
      "generateTemplate",
      "createPartnerPage",
      "getHTML",
      "getGenerateResponse",
      "getUnsplashImage",
      "stopPartnerPageGeneration",
      "fetchProfilePageStatus"
    ]),
    openDeleteModal() {
      this.deleteConfirmModal.modalTitle = "NOTIFICATION";
      this.deleteConfirmModal.modalContent =
        "Are you sure you want to stop this Profile page Generation?";
      this.deleteConfirmModal.isShowPopup = true;
    },
    deleteModalCallBack(action) {
      if (action === "Confirm") {
        const partners_page_id =
          this.generatedPartnerRes?.partners_page_id;
        this.stopGeneration(
          partners_page_id,
        );
      }
      this.deleteConfirmModal.isShowPopup = false;
      this.deleteConfirmModal.data = null;
    },
    stopGeneration(partners_page_id) {
      this.stopPartnerPageGeneration(
        partners_page_id,
      ).then((res) => {
        this.getGenerationResponse();
      });
    },
    async onSubmit() {
      const isValid = await this.$refs.templateForm.validate();
      if (!isValid) {
        this.showToast({
          class: "bg-danger text-white",
          message: "Please fill mandatory fields!",
        });
        return;
      }
      this.isGenerating = true;
      this.htmlString = null;
      this.currentStep = 0;

      let payload = {
        name: this.userData?.name,
        description: this.userData?.description,
        organisation_id: this.getOrgIDFromAccessToken,
        template: this.userData?.template?.code,
        home_url: this.userData?.home_url,
        login_url: this.userData?.login_url,
        register_url: this.userData?.register_url
      };

      if (this.userData.content_type.code === "url") {
        payload = {
          ...payload,
          link: this.userData?.url,
        };
      } else {
        payload = {
          ...payload,
          file: this.userData?.file,
        };
      }
      this.generateTemplate(payload).then((res) => {
        this.isGenerating = false;
        if (res.status === 200) {
          // setTimeout(() => {
          //   this.getGenerationResponse();
          // }, 5000);
          const partners_page = res.data.partners_page;
          if (partners_page.completed) {
            const message = JSON.parse(
              res.data?.partners_page?.assistant_message
            );

            const parsedRes = message?.content[0]?.text?.value;
            const htmlString = this.extractCodeBlocks(parsedRes);
            if (htmlString) {
              this.htmlString = htmlString;
              setTimeout(() => {
                this.getImageAsBase64();
                this.removeHref();
              }, 500);
            }
          } else {
            this.$store.commit("SET_GENERATED_PARTNER_RES", [partners_page]);
          }
        }
      });
      clearTimeout(this.interval);
      this.interval = setTimeout(this.getStatus, 5000);
      this.setStepDelay(5, 10000);
    },
    setStepDelay(maxStep, delay) {
      let step = 0;
      const interval = setInterval(() => {
        if (step < maxStep) {
          this.currentStep = step + 1;
          step++;
        } else {
          clearInterval(interval);
        }
      }, delay);
    },
    getGenerationResponse() {
      const organisation_id = this.getOrgIDFromAccessToken;
      this.getGenerateResponse(organisation_id).then((res) => {
        if (res?.length && res[0]?.assistant_message && !res[0].submitted) {
          const parsedRes = res[0]?.assistant_message?.content[0]?.text?.value;
          const htmlString = this.extractCodeBlocks(parsedRes);
          if (htmlString) {
            this.htmlString = htmlString;
            setTimeout(() => {
              this.getImageAsBase64();
              // this.removeHref();
            }, 500);
          }
        } else if (this.htmlFileString) {
          this.htmlString = this.htmlFileString;
        }
      });
    },
    handleChangeSelect(name, value) {
      Vue.set(this.userData, name, value);
    },
    handleChangeInput(name, value) {
      Vue.set(this.userData, name, value);
    },
    async getImageURL(query) {
      const res = await this.getUnsplashImage(query);
      return res?.data?.results[0]?.urls?.regular;
    },
    handleSelectedFile(event) {
      const ref = `input_file`;
      const size = event.target.files[0].size;
      if (Math.round(size / (1024 * 1024)) <= 10) {
        const file = event.target.files[0];
        if (file.type.startsWith("image/")) {
          this.cropperModal.fileData = file;
          this.cropperModal.isShowPopup = true;
        } else {
          this.$refs[ref].value = "";
          this.showToast({
            class: "bg-danger text-white",
            message: "Please select Image",
          });
          return;
        }
      } else {
        this.$refs[ref].value = "";
        this.showToast({
          class: "bg-danger text-white",
          message: "File too Big, please select a file less than 2mb",
        });
        return;
      }
    },
    handleColorChange(value) {
      this.selectedElement.color = value;
    },
    handleTextInput(name, value) {
      Vue.set(this.selectedElement, name, value);
    },
    async cropperModalCallBack(action, data) {
      if (action === false) {
        this.cropperModal.isShowPopup = false;
        this.cropperModal.fileData = null;
        this.cropperModal.aspectRatio = 0;
        const ref = `input_file`;
        this.$refs[ref].value = "";
      } else {
        let { fileBlob: file, fileName } = data;
        if (file) {
          try {
            file = await this.convertToBase64(file);
          } catch (error) {
            console.error("Error converting to base64:", error);
          }
        }
        Vue.set(this.selectedElement, "file", file);
        this.cropperModal.isShowPopup = false;
        this.cropperModal.fileData = null;
        this.cropperModal.aspectRatio = 0;
      }
    },
    convertToBase64(blob) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onload = () => {
          const base64String = reader.result;
          const base64DataUrl = base64String.split(",")[1];
          resolve(base64DataUrl);
        };
        reader.onerror = (error) => {
          reject(error);
        };
      });
    },
    extractCodeBlocks(text) {
      // Regular expression to match code blocks enclosed within triple backticks (`) or triple tildes (~)
      const regex = /```([\s\S]+?)```|~~~([\s\S]+?)~~~/g;

      let matches = [];
      let match;

      // Iterate over matches found in the text
      while ((match = regex.exec(text)) !== null) {
        // Check if the match exists and push it to the matches array
        if (match[1]) {
          matches.push(match[1].trim().replace("html\n", ""));
        } else if (match[2]) {
          matches.push(match[2].trim().replace("html\n", ""));
        }
      }

      return matches;
    },
    getImageAsBase64() {
      const elementsInsideIframe = this.getIframeElements("*");
      elementsInsideIframe.forEach((element) => {
        const isBackIMG = this.checkIMGBackground(element);
        let imgURL = isBackIMG ? isBackIMG : element.src;
        let imgAlt = isBackIMG ? "Medical Background Image" : element.alt;
        this.getImageURL(imgAlt).then((imgURL) => {
          const image = new Image();
          image.crossOrigin = "Anonymous";
          image.onload = () => {
            const canvas = document.createElement("canvas");
            canvas.width = image.width;
            canvas.height = image.height;
            const ctx = canvas.getContext("2d");
            ctx.drawImage(image, 0, 0);
            const dataURL = canvas.toDataURL("image/png");
            if (isBackIMG) {
              element.style.backgroundImage = `url(${dataURL})`;
              element.style.maxHeight = "250px";
            } else {
              element.src = dataURL;
            }
          };
          image.src = imgURL;
        });
      });
    },
    removeHref() {
      const iframe = document.getElementById("iframe");
      const iframeDocument =
        iframe.contentDocument || iframe.contentWindow.document;
      const elementsInsideIframe =
        iframeDocument.querySelectorAll("a,img,section");
      elementsInsideIframe.forEach((element) => {
        if (element.tagName === "IMG") {
          element.style.width = "100%";
        } else if (element.tagName === "SECTION") {
          element.style.marginBottom = "10px";
        } else element.href = "javascript:void(0)";
      });
    },
    getIframeElements(
      tags = "p, img, h1, h2, h3, h4, h5, h6, button, a, section, li"
    ) {
      const iframe = document.getElementById("iframe");
      const iframeDocument =
        iframe.contentDocument || iframe.contentWindow.document;
      // Get all elements inside the iframe
      const elementsInsideIframe = iframeDocument.querySelectorAll(tags);
      return elementsInsideIframe;
    },
    startEditMode() {
      this.isEditMode = true;
      const elementsInsideIframe = this.getIframeElements();
      elementsInsideIframe.forEach((element) => {
        const parentNode = element.parentNode;
        const isParentSection = parentNode.tagName === "SECTION";
        const isSection = element.tagName === "SECTION";
        let isBackIMG = false;
        if (isSection) {
          isBackIMG = this.checkIMGBackground(element);
        }
        // if (!isSection || isBackIMG) {
        element.addEventListener("mouseover", this.addBorder);
        element.addEventListener("mouseout", this.removeBorder);
        element.addEventListener("click", this.setSelectedElement);
        // }
      });
    },
    addBorder(event) {
      const ele = event.target;
      ele.classList.add("border"); // Blue border
      ele.classList.add("border-info"); // Blue border
    },
    removeBorder(event) {
      const ele = event.target;
      ele.classList.remove("border");
      ele.classList.remove("border-info");
    },
    stopEditMode() {
      this.isEditMode = false;
      this.removeListeners();
    },
    saveTemplate() {
      this.stopEditMode();

      const htmlBlob = this.generateHTMLBlob();
      const organisation_id = this.getOrgIDFromAccessToken;
      const payload = {
        file: htmlBlob,
        fileName: `profile_org_${organisation_id}.html`,
      };
      this.$emit("saveTemplate", payload);
    },
    regenerateTemplate() {
      this.htmlString = "";
    },
    setSelectedElement(event) {
      let ele = event.target;
      this.isShowEdit = true;
      if (ele.parentNode.tagName === "SECTION") {
        ele = ele.parentNode;
      }
      this.selectedElement.element = ele;
      this.selectedElement.tagName = ele.tagName;
      this.selectedElement.textContent = ele.textContent || null;
      this.selectedElement.color = this.getColorCodeElement(ele);
      this.selectedElement.isBackIMG = this.checkIMGBackground(ele);
      this.selectedElement.isLogo = this.checkIMGLogo(ele);
      this.selectedElement.isIMG = ele.src;
    },
    getColorCodeElement(ele) {
      const computedStyle = window.getComputedStyle(ele);
      const color = computedStyle.getPropertyValue("color");
      return color ? this.rgbToHex(color) : null;
    },
    checkIMGLogo(element) {
      return element.tagName === "IMG"
        ? element.alt?.toLowerCase().includes("logo")
        : false;
    },
    checkIMGBackground(element) {
      const computedStyle = window.getComputedStyle(element);
      const backgroundImage =
        computedStyle.getPropertyValue("background-image");
      return backgroundImage && backgroundImage !== "none"
        ? backgroundImage.match(/url\(['"]?(.*?)['"]?\)/)[1]
        : false;
    },
    updateElement(isUpdate) {
      this.isShowEdit = false;
      if (isUpdate) {
        if (this.selectedElement.isIMG) {
          this.selectedElement.element.src = `data:image/png;base64,${this.selectedElement.file}`;
          if (this.selectedElement.isLogo)
            this.selectedElement.element.style.width = "auto";
        }
        if (this.selectedElement.isBackIMG) {
          this.selectedElement.element.style.backgroundImage = `url(data:image/png;base64,${this.selectedElement.file})`;
          this.selectedElement.element.style.backgroundRepeat = "no-repeat";
          this.selectedElement.element.style.backgroundPosition = "center";
          this.selectedElement.element.style.backgroundSize = "cover";
          this.selectedElement.element.style.minHeight = "200px";
          this.selectedElement.element.style.maxHeight = "250px";
        }
        if (this.selectedElement.color) {
          this.selectedElement.element.style.color = this.selectedElement.color;
        }
        if (
          this.selectedElement.textContent &&
          !this.selectedElement.isBackIMG
        ) {
          this.selectedElement.element.textContent =
            this.selectedElement.textContent;
        }
      }
    },

    deleteElement() {
      this.selectedElement.element.remove();
    },
    listenerEvent() {},
    removeListeners() {
      const elementsInsideIframe = this.getIframeElements();
      elementsInsideIframe.forEach((element) => {
        element.removeEventListener("mouseover", this.addBorder);
        element.removeEventListener("mouseout", this.removeBorder);
        element.removeEventListener("click", this.setSelectedElement);
      });
    },
    selectedFile(event) {
      const size = event.target.files[0].size;
      if (Math.round(size / (1024 * 1024)) <= 2) {
        const file = event.target.files[0];
        const fileExt = file.name.split(".").pop();
        const fileTypeString = this.acceptedFile;
        if (fileTypeString.includes(fileExt)) {
          this.userData = {
            ...this.userData,
            file: {
              file,
              fileName: file?.name,
            },
          };
        } else {
          let fileTypeError = fileTypeString;
          this.$refs.fileref.value = "";
          this.showToast({
            class: "bg-danger text-white",
            message: `Allowed File Type ${fileTypeError}`,
          });
          return;
        }
      } else {
        this.$refs.fileref.value = "";
        this.showToast({
          class: "bg-danger text-white",
          message: "File too Big, please select a file less than 2mb",
        });
        return;
      }
    },
    arrayToComma(data) {
      return data?.map((v) => `.${v}`).join(", ");
    },
    rgbToHex(rgb) {
      const values = rgb.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);
      return (
        "#" +
        (
          (1 << 24) +
          (parseInt(values[1]) << 16) +
          (parseInt(values[2]) << 8) +
          parseInt(values[3])
        )
          .toString(16)
          .slice(1)
      );
    },
    generateHTMLBlob() {
      const iframe = document.getElementById("iframe");

      // Get the content of the iframe
      const iframeContent = iframe.contentDocument.documentElement.outerHTML;

      // Create a Blob from the iframe content
      const blob = new Blob([iframeContent], { type: "text/html" });
      return blob;
    },
    convertHTMLtoDoc(url) {
      this.getHTML(url).then((result) => {
        this.htmlString = result?.data;
      });
    },
    setStep(index) {
      this.currentStep = index;
    },
    async getStatus() {
      try {
        clearTimeout(this.interval);
        const response = await this.fetchProfilePageStatus(this.getOrgIDFromAccessToken);
        this.isLoading = false;
        let res_status = response.data.status;

        if (response?.status === 400) {
          clearTimeout(this.interval);
        }

        if (this.stepMessages[this.contentType]) {
          let index = this.stepMessages[this.contentType].indexOf(res_status);

          if (index !== -1) {
            this.setStep(index);
          } else {
            console.warn("res_status not found in stepMessages:", res_status);
          }
        }

        if (['completed', 'failed'].includes(res_status)) {
          clearTimeout(this.interval);
          if(res_status == 'completed') {
            this.getGenerationResponse()
          } else {
            this.isProcessing = false;
            this.htmlString = "";
            this.showToast({
              class: "bg-danger text-white",
              message: "Failed to generate profile. Try again.",
            });
          }
        } else {
          this.interval = setTimeout(this.getStatus, 10000);
        }
      } catch (error) {
        console.error('Error fetching status:', error);
        clearTimeout(this.interval);
      }
    },
    upperCase(step) {
      return step
        .replace(/_/g, " ")
        .replace(/\b\w/g, (char) => char.toUpperCase());
    }
  },
  async mounted() {
    this.isLoading = true;
    await this.getGenerationResponse();
    await this.getStatus()
  },
  beforeDestroy() {
    clearTimeout(this.interval);
  }
};
</script>
<style lang="scss" scoped>
.preview,
.preview-body {
  padding: 22px 24px 20px;
  background-color: var(--modal-background);
  border-radius: 3px;
  box-shadow: 0 17px 50px 0 rgba(var(--shadow-rgb), 0.19),
    0 12px 15px 0 rgba(var(--shadow-rgb), 0.24);
  min-height: 300px;
}
.place_holder_img {
  height: 300px;
  // width: auto;
  object-fit: cover;
}
.generation {
  position: absolute;
  top: 50%;
  left: 35%;
}

.text-green {
  color: green !important;
}

.stepper-container {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
}

.step-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.stepper {
  position: absolute;
  width: 99%;
  height: 4px;
  background-color: #dadada;
  z-index: 1;
  margin: auto;
}

.stepper-progress {
  height: 100%;
  width: 0%;
  background-color: green;
  transition: width 0.4s ease-in-out;
  z-index: 2;
}

.step {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 3px solid rgb(210, 209, 211);
  background-color: rgb(162, 161, 161);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.step.active {
  background-color: green;
}

.step-message {
  position: absolute;
  font-size: 16px;
  top: 100%;
  font-weight: bold;
  color: darkblue;
  text-align: center;
  white-space: nowrap;
}

@media (min-width: 1792px) {
  .stepper-container {
    max-width: 1700px;
  }

  .stepper {
    max-width: 118rem
  }
}
</style>
